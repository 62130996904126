@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  min-height: 100%;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Button_root__G_l9X {
  display: inline-flex;
  transform: none;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  border-width: 1px;
  border-color: transparent;
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0/var(--tw-bg-opacity));
  padding: 0.5rem 1.25rem;
  text-align: center;
  font-weight: 600;
  text-transform: none;
  line-height: 1.5rem;
  --tw-text-opacity: 1;
  color: rgb(228 228 231/var(--tw-text-opacity));
  --tw-shadow: 0 1px 2px 0 rgba(0,0,0,.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
  transition-property: color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,-webkit-backdrop-filter;
  transition-property: color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter;
  transition-property: color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
}

.Delete_Button_root__G_l9X {
  display: inline-flex;
  transform: none;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  border-width: 1px;
  border-color: transparent;
  --tw-bg-opacity: 1;
  padding: 0.5rem 1.25rem;
  text-align: center;
  font-weight: 600;
  text-transform: none;
  line-height: 1.5rem;
  --tw-text-opacity: 1;
  --tw-shadow: 0 1px 2px 0 rgba(0,0,0,.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
  transition-property: color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,-webkit-backdrop-filter;
  transition-property: color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter;
  transition-property: color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
}

.login-form {
  display: flex;
  gap: 4px;
}

.login-form-large {
  gap: 16px;
}

.login-form-direction-vertical {
  flex-direction: column;
  margin: 8px 0px;
}

.auth-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 4px;
  font-size: 14px;
  padding: 10px 15px;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  width: 100%;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 100ms;
}

.auth-button-color-primary {
  background-color: #000000;
  color: #ffffff;
  border-color: #222222;
}

.login-form-label {
  font-size: 14px;
  margin-bottom: 8px;
  color: gray;
  display: block;
}

.login-field-password {
  letter-spacing: 0px;
}

.login-field-default {
  letter-spacing: 0px;
}

.login-field {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 100ms;
  background: transparent;
  border-radius: 4px;
  padding: 10px 15px;
  cursor: text;
  border-width: 1px;
  border-color: lightgray;
  border-style: solid;
  font-size: 14px;
  width: 100%;
  color: black;
  box-sizing: border-box;
}

.sub-text-login {
  font-size: 13px;
  margin-bottom: 4px;
  color: gray;
  display: block;
  text-align: center;
  text-decoration: underline;
}

.error-message-color-danger {
  color: #ff0000 !important;
}

.error-message {
  font-size: 13px;
  margin-bottom: 8px;
}

.footer-ele {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}