:root {
  --grey-background: #e5e5e5;
  --dark-background: #c7c7c7;
  --chat-background: #d9d9d9;
  --blue: #283556;
  --primary-background: #FF7903;
  --primary-middle-background: #ffad66;
  --primary-end-background: #FF7903;
  --white: #ffffff;
  --light-grey: #f0f0f3;
  --shadow-grey: #cccccf;
  --button-shadow-blue: #ffbb80;
  --login-background: #f2f5ff;
  --login-left-background: #dee5ff;
  --danger: #dc2626;
}

.body {
  background-color: var(--grey-background);
}

.bg-common {
  background-color: var(--white);
}

.bg-footer-common {
  background-color: var(--light-grey);
}

.common-card {
  border-radius: 30px;
  background: var(--light-grey);
  box-shadow: 10px 10px 20px var(--shadow-grey), -10px -10px 20px var(--white);
}

.common-bot-card {
  background: var(--light-grey);
  box-shadow: 10px 10px 20px var(--shadow-grey), -10px -10px 20px var(--white);
}
     
.common-btn-grad {
  background-image: linear-gradient(to right, var(--primary-background) 0%, var(--primary-middle-background)  50%, var(--primary-end-background)  100%);
  padding: 12px 34px;
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: var(--white);            
  box-shadow: 0 4px 16px 0 var(--button-shadow-blue), 0 6px 20px 0 var(--button-shadow-blue);
  border-radius: 10px;
  display: block;
}

.common-btn-outline {
  padding: 12px 34px;
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: var(--blue);            
  box-shadow: 0 4px 16px 0 var(--button-shadow-blue), 0 6px 20px 0 var(--button-shadow-blue);
  border-radius: 10px;
  display: block;
  border: 2px solid var(--primary-background);
  background: transparent;
}

.common-btn-grad:hover {
  background-position: right center;
  color: var(--white);
  text-decoration: none;
}

.common-chat-bg-color {
  background-color: var(--chat-background);
}

.common-chat-round {
  border-radius: 2rem;
}

h1, h2, h3, h4, h5, h6, p, span {
  color: var(--blue) !important;
}

.common-chat-input-round {
  border-radius: 0.6rem;
}

.common-chat-user-bg-color {
  background-color: var(--primary-background);
  color: var(--white);
}

.text-orange {
  color: var(--primary-background) !important;
}

.text-blue {
  background-color: var(--blue) !important;
}

.delete-link {
  cursor: pointer;
  color: var(--danger);
}

.color-white {
  color: var(--white) !important;
}

.w-50 {
  width: 50%;
}